import React, { useState } from 'react'
import { AccordionItem } from '@reach/accordion'
import { useSpring } from 'react-spring'
import { ChevronRight, HelpCircle } from 'react-feather'
import '@reach/accordion/styles.css'
import '@reach/tooltip/styles.css'
import { FeatureName, CustomizedToolTip, AnimatedDescription, ReadMore, ReadMoreBtn } from './styles'

interface Item {
	item: {
		description: {
			description: string
		}
		faqCategory: string | null
		slug: string
		title: string
		tooltip: string
		isOpen?: boolean
	}
}

const Accordion = ({ item }: Item): JSX.Element => {
	const [isOpen, toggle] = useState(item.isOpen)
	const clickHandler = () => {
		toggle(!isOpen)
	}

	const props = useSpring({
		from: { height: 0, opacity: 0, transform: 'translate3d(20px,0,0)' },
		to: {
			height: isOpen ? 'auto' : 0,
			opacity: isOpen ? 1 : 0,
			transform: `translate3d(${isOpen ? 0 : 20}px,0,0)`,
		},
	})
	return (
		<AccordionItem>
			<FeatureName
				as="button"
				onClick={() => {
					clickHandler()
				}}
			>
				{isOpen ? <ChevronRight className="down" /> : <ChevronRight className="right" />}
				{item.title}
				{item.tooltip != null ? (
					<CustomizedToolTip label={item.tooltip}>
						<button>
							<HelpCircle width="18px" height="18px" fill="#58c8df" color="#fff" />
						</button>
					</CustomizedToolTip>
				) : (
					''
				)}
			</FeatureName>
			<AnimatedDescription style={props}>
				{item.description.description}
				<ReadMore target="_blank" to={`https://maytronics.com.au/support/faq/${item.slug}`}>
					<ReadMoreBtn>Read More...</ReadMoreBtn>
				</ReadMore>
			</AnimatedDescription>
		</AccordionItem>
	)
}

export default Accordion
