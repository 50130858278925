import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import ProductInfo from '../../../sea-site/src/components/product/ProductInfo'
import ItemSummary from '../../../sea-site/src/components/product/item-summary'
import Series from '../../../sea-site/src/components/product/Series'
import WhichModel from '../components/which-model'
const isBrowser = typeof window !== 'undefined'

export interface Data {
	active: null | boolean
	body?: {
		raw: string
	}
	brand: string
	brandPage: {
		slug: string
	}
	brochure: {
		file: {
			url: string
		}
	}[]
	categories: {
		slug: string
		title: string
	}
	comparisonItem: boolean
	degreeView: string
	distinction: null | string
	features: {
		description: {
			description: string
		}
		faqCategory: null | string
		slug: string
		title: string
		tooltip: string
		isOpen?: boolean
	}[]
	frequentlyAskedQuestions?: {
		title: string
		description: {
			description: string
		}
	}[]
	images: {
		description: string
		fluid: {
			src: string
			srcWebp: string
		}
	}[]
	inStock: boolean | null
	linkTo: {
		slug: string
		model: string
	}
	manual: {
		file: {
			url: string
		}
	}[]
	meta?: {
		lang: string
		url: string
	}[]
	model: string
	node_locale?: string
	price: number
	seoDescription?: string
	seoKeywords?: string[]
	seoTitle: string
	series: string
	seriesPage: {
		backgroundImage: {
			fluid: {
				src: string
				srcWebp: string
			}
		}
		banner: {
			backgroundImage: {
				fluid: {
					src: string
					srcWebp: string
				}
			}
			description: {
				raw: string
			}
		}
		media: {
			file: {
				url: string
			}
		} | null
		seoDescription: string
		slug: null
	}
	shippingClass: string
	sku: string
	slug: string
	tags: string[]
	video?: string[]
	specifications: {
		spec: string
		detail: string
		details: string[]
	}[]
}

export interface Zoom {
	slug: string
	node_locale: string
	images: {
		description: string
		fluid: {
			src: string
			srcWebp: string
		}
	}[]
}

export interface BannerData {
	backgroundImage: {
		fluid: {
			src: string
			srcWebp: string
		}
	}
	banner: {
		backgroundImage: {
			fluid: {
				src: string
				srcWebp: string
			}
		}
	}
}

interface Props {
	data: {
		data: {
			edges: {
				node: Data
			}[]
		}
		zoom: {
			edges: {
				node: Zoom
			}[]
		}
		xl: {
			edges: {
				node: {
					seriesPage: BannerData
				}
			}[]
		}
		l: {
			edges: {
				node: {
					seriesPage: BannerData
				}
			}[]
		}
		md: {
			edges: {
				node: {
					seriesPage: BannerData
				}
			}[]
		}
		sm: {
			edges: {
				node: {
					seriesPage: BannerData
				}
			}[]
		}
		xs: {
			edges: {
				node: {
					seriesPage: BannerData
				}
			}[]
		}
		xxs: {
			edges: {
				node: {
					seriesPage: BannerData
				}
			}[]
		}
		lastBanner: {
			edges: {
				node: {
					backgroundImage: {
						file: {
							url: string
						}
					}
					description: {
						raw: string
					}
					hook: string
					mainActionText: string
					mainActionUrl: string
					node_locale: string
				}
			}[]
		}
	}
}

const ProductPageTemplate = ({ data: { data, lastBanner, xl, l, md, sm, xs, xxs, zoom } }: Props): JSX.Element => {
	const [{ node }] = data.edges
	const [{ node: filteredLastBanner }] = lastBanner.edges
	const [{ node: extraLarge }] = xl.edges
	const [{ node: largeImage }] = l.edges
	const [{ node: medImage }] = md.edges
	const [{ node: smallImage }] = sm.edges
	const [{ node: extraSmallImage }] = xs.edges
	const [{ node: xxSmallImage }] = xxs.edges
	const [{ node: zoomImage }] = zoom.edges

	// desconstruct backgrounds
	const { seriesPage: xlarge } = extraLarge
	const { seriesPage: large } = largeImage
	const { seriesPage: med } = medImage
	const { seriesPage: small } = smallImage
	const { seriesPage: xsmall } = extraSmallImage
	const { seriesPage: xxsmall } = xxSmallImage
	const {
		slug,
		seoTitle,
		seoKeywords,
		seoDescription,
		images,
		brand,
		series,
		model,
		categories,
		price,
		sku,
		shippingClass,
		body,
		video,
		seriesPage,
		features,
		specifications,
		brochure,
		manual,
		distinction,
		tags,
		brandPage,
		comparisonItem,
		degreeView,
		active,
		meta,
		inStock,
		linkTo,
	} = node

	useEffect(() => {
		/**
		 * Google Analytics - Tag Manager - Enhanced Ecommerce
		 * Measuring product impressions for every search result change
		 * */
		const floatPrice = (price: number) =>
			price >= 1
				? typeof price != 'number'
					? parseFloat(price).toFixed(2)
					: price.toFixed(2)
				: parseFloat('0').toFixed(2)
		const product = {
			name: node.seoTitle, // the name of the product, including Title and Subtitle
			id: node.sku, // product serial number
			price: Number(floatPrice(node.price)), // actual price per unit or in case of fixed price discounts the discounted price
			brand: node.brand, // product brand
			category: [node.categories && node.categories.title].toString(), // Dolphin domestic, Dolphin commercial, mineral swim, ozone swim
		}
		if (isBrowser && window.dataLayer) {
			window.dataLayer.push({
				event: 'productDetails',
				ecommerce: {
					detail: {
						actionField: { list: 'Category List' }, // 'detail' actions have an optional list property.
						products: [product],
					},
				},
			})
		}
	}, [node])

	return (
		<Layout>
			<SEO title={seoTitle} keywords={seoKeywords} description={seoDescription}>
				{meta
					? meta.map((x: { [field: string]: string }, i: number) => {
							return <link key={i} rel="alternate" href={x.url} hrefLang={x.lang} />
					  })
					: ''}
			</SEO>
			<ProductInfo
				images={images != null ? images : []}
				brand={brand}
				series={series}
				model={model}
				price={price}
				sku={sku}
				categories={categories}
				shippingClass={shippingClass}
				slug={slug}
				val={1}
				features={features}
				specifications={specifications}
				brochure={brochure}
				manual={manual}
				distinction={distinction}
				tags={tags}
				brandPage={brandPage}
				seoTitle={seoTitle}
				comparisonItem={comparisonItem}
				seriesPage={seriesPage}
				degreeView={degreeView}
				zoom={zoomImage}
				active={active}
				inStock={inStock}
				linkTo={linkTo}
			/>
			<ItemSummary
				body={body}
				video={video}
				brand={brand}
				tags={tags}
				brandPage={brandPage}
				comparisonItem={comparisonItem}
				seoTitle={seoTitle}
				active={active}
				series={series}
				model={model}
				categories={categories}
			/>
			<Series
				series={series}
				seriesPage={seriesPage}
				brandPage={brandPage}
				xlarge={xlarge}
				large={large}
				medium={med}
				small={small}
				xsmall={xsmall}
				xxsmall={xxsmall}
				slug={slug}
			/>
			<WhichModel data={filteredLastBanner} />
		</Layout>
	)
}

export default ProductPageTemplate

export const ProductPageQuery = graphql`
	query ProductPageBySlug($slug: String!) {
		data: allContentfulProduct(filter: { slug: { eq: $slug } }) {
			edges {
				node {
					slug
					seoTitle
					seoKeywords
					seoDescription
					node_locale
					price
					sku
					inStock
					distinction
					tags
					comparisonItem
					active
					linkTo {
						slug
						model
					}
					meta {
						url
						lang
					}
					brandPage {
						slug
					}
					images {
						id
						description
						fluid(quality: 60, maxWidth: 450, maxHeight: 450) {
							src
							srcWebp
						}
					}
					degreeView
					brand
					model
					series
					categories {
						title: seoTitle
						slug
					}
					shippingClass
					body {
						raw
					}
					video
					specifications {
						spec
						detail
						details
					}
					frequentlyAskedQuestions {
						title
						description {
							description
						}
					}
					brochure {
						file {
							url
						}
					}
					manual {
						file {
							url
						}
					}
					features {
						title
						description {
							description
						}
						tooltip
						slug
						faqCategory
					}
					seriesPage {
						seoDescription
						slug
						media {
							file {
								url
							}
							description
						}

						backgroundImage {
							fluid(quality: 80, maxWidth: 1920, maxHeight: 800, cropFocus: CENTER, resizingBehavior: FILL) {
								src
								srcWebp
							}
						}
						banner {
							description {
								raw
							}

							backgroundImage {
								fluid(quality: 80, maxWidth: 1920, maxHeight: 800, cropFocus: CENTER, resizingBehavior: FILL) {
									src
									srcWebp
								}
							}
						}
					}
				}
			}
		}
		zoom: allContentfulProduct(filter: { slug: { eq: $slug } }) {
			edges {
				node {
					slug
					node_locale
					images {
						id
						description
						fluid(quality: 60, maxWidth: 800, maxHeight: 800) {
							src
							srcWebp
						}
					}
				}
			}
		}
		xl: allContentfulProduct(filter: { slug: { eq: $slug } }) {
			edges {
				node {
					node_locale
					seriesPage {
						backgroundImage {
							fluid(quality: 80, maxWidth: 1699, maxHeight: 800, cropFocus: CENTER, resizingBehavior: FILL) {
								src
								srcWebp
							}
						}

						banner {
							backgroundImage {
								fluid(quality: 80, maxWidth: 1699, maxHeight: 800, cropFocus: CENTER, resizingBehavior: FILL) {
									src
									srcWebp
								}
							}
						}
					}
				}
			}
		}
		l: allContentfulProduct(filter: { slug: { eq: $slug } }) {
			edges {
				node {
					node_locale
					seriesPage {
						backgroundImage {
							fluid(quality: 80, maxWidth: 1399, maxHeight: 700, cropFocus: CENTER, resizingBehavior: FILL) {
								src
								srcWebp
							}
						}

						banner {
							backgroundImage {
								fluid(quality: 80, maxWidth: 1399, maxHeight: 700, cropFocus: CENTER, resizingBehavior: FILL) {
									src
									srcWebp
								}
							}
						}
					}
				}
			}
		}
		md: allContentfulProduct(filter: { slug: { eq: $slug } }) {
			edges {
				node {
					node_locale
					seriesPage {
						backgroundImage {
							fluid(quality: 80, maxWidth: 1279, maxHeight: 600, cropFocus: CENTER, resizingBehavior: FILL) {
								src
								srcWebp
							}
						}

						banner {
							backgroundImage {
								fluid(quality: 80, maxWidth: 1279, maxHeight: 600, cropFocus: CENTER, resizingBehavior: FILL) {
									src
									srcWebp
								}
							}
						}
					}
				}
			}
		}
		sm: allContentfulProduct(filter: { slug: { eq: $slug } }) {
			edges {
				node {
					node_locale
					seriesPage {
						backgroundImage {
							fluid(quality: 80, maxWidth: 1023, maxHeight: 500, cropFocus: CENTER, resizingBehavior: FILL) {
								src
								srcWebp
							}
						}

						banner {
							backgroundImage {
								fluid(quality: 80, maxWidth: 1023, maxHeight: 500, cropFocus: CENTER, resizingBehavior: FILL) {
									src
									srcWebp
								}
							}
						}
					}
				}
			}
		}

		xs: allContentfulProduct(filter: { slug: { eq: $slug } }) {
			edges {
				node {
					node_locale
					seriesPage {
						backgroundImage {
							fluid(quality: 80, maxWidth: 767, maxHeight: 350, cropFocus: CENTER, resizingBehavior: FILL) {
								src
								srcWebp
							}
						}

						banner {
							backgroundImage {
								fluid(quality: 80, maxWidth: 767, maxHeight: 350, cropFocus: CENTER, resizingBehavior: FILL) {
									src
									srcWebp
								}
							}
						}
					}
				}
			}
		}
		xxs: allContentfulProduct(filter: { slug: { eq: $slug } }) {
			edges {
				node {
					node_locale
					seriesPage {
						backgroundImage {
							fluid(quality: 80, maxWidth: 524, maxHeight: 350, cropFocus: CENTER, resizingBehavior: FILL) {
								src
								srcWebp
							}
						}

						banner {
							backgroundImage {
								fluid(quality: 80, maxWidth: 524, maxHeight: 350, cropFocus: CENTER, resizingBehavior: FILL) {
									src
									srcWebp
								}
							}
						}
					}
				}
			}
		}
		lastBanner: allContentfulBanner(filter: { contentful_id: { eq: "5UBkM4QSv0bHOcKjyMBmw1" } }) {
			edges {
				node {
					hook
					node_locale
					mainActionText
					mainActionUrl
					description {
						raw
					}
					backgroundImage {
						file {
							url
						}
					}
				}
			}
		}
	}
`
ProductPageTemplate.propTypes = {
	data: PropTypes.object,
}
