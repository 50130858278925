import React, { useState } from 'react'
import { BLOCKS, INLINES, MARKS, Block, Inline, Text } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import useProduct from '../../../../sea-site-theme/src/hooks/useProduct'
import useWindowResize from '../../../../sea-site-theme/src/hooks/useWindowResize'
import {
	ItemSummaryContainer,
	ItemDetailsContainer,
	ItemTitle,
	ItemDetails,
	ItemImage,
	VideoContainer,
	ThumbnailsContainer,
	ThumbContainer,
	Thumbs,
	SvgContainer,
	StyledSvg,
	SummaryCtaContainer,
	SummaryButton,
	Book,
	Novideo,
	HyperLinkText,
	Bold,
	Italic,
	UnderlinedText,
} from './styles'

interface ItemSummaryProps {
	body?: {
		raw: string
	}
	video?: string[]
	brand: string
	tags: string[]
	brandPage: {
		slug: string
	}
	comparisonItem: boolean
	active: null | boolean
	seoTitle: string
	series: string
	model: string
	categories: {
		slug: string
		title: string
	}
}

const options = {
	renderMark: {
		[MARKS.BOLD]: function BoldText(text: React.ReactChild | React.ReactFragment | null | undefined) {
			return <Bold>{text}</Bold>
		},
		[MARKS.ITALIC]: function ItalicText(text: React.ReactChild | React.ReactFragment | null | undefined) {
			return <Italic>{text}</Italic>
		},
		[MARKS.UNDERLINE]: function Underline(text: React.ReactChild | React.ReactFragment | null | undefined) {
			return <UnderlinedText>{text}</UnderlinedText>
		},
	},
	renderNode: {
		[BLOCKS.HEADING_2]: function Heading2(
			node: Block | Inline | Text,
			children: React.ReactChild | undefined | null | React.ReactFragment
		) {
			return <ItemTitle>{children}</ItemTitle>
		},
		[BLOCKS.PARAGRAPH]: function Paragraph(
			node: Block | Inline | Text,
			children: React.ReactChild | undefined | null | React.ReactFragment
		) {
			return <ItemDetails>{children}</ItemDetails>
		},
		[BLOCKS.EMBEDDED_ASSET]: function EmbeddedAsset(node: Block | Inline | Text) {
			const asset = node.data && node.data.target && node.data.target.fields
			if (asset === undefined) return // nothing
			const { file, description } = asset
			return <ItemImage src={file['en-AU'].url} alt={description['en-AU']} />
		},
		[INLINES.HYPERLINK]: function HyperLink(
			node: Inline | Block | Text,
			children: React.ReactChild | undefined | null | React.ReactFragment
		) {
			return (
				<HyperLinkText href={node.data.uri} target="_blank">
					{children}
				</HyperLinkText>
			)
		},
	},
}

const ItemSummary = ({
	body,
	video,
	brand,
	tags,
	brandPage,
	seoTitle,
	comparisonItem,
	active,
	series,
	model,
	categories,
}: ItemSummaryProps): JSX.Element => {
	const comparison = comparisonItem === null ? true : comparisonItem ? true : false
	const [currentVid, setCurrentVid] = useState(video != null ? video[0] : video)

	const [...thumbs] = video != null ? video : []
	const filteredThumbs = thumbs.filter((x) => {
		return x != currentVid
	})

	const commercialCheck = tags.includes('commercial')
	const residentialCheck = tags.includes('residential')
	const tagCheck = commercialCheck ? 'commercial' : residentialCheck ? 'residential' : ''

	const { addToTable } = useProduct()
	const { width } = useWindowResize()
	const checkLength = (
		key: string,
		e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
		brandPage: string,
		tagCheck: string,
		title: string
	) => {
		const items = window.sessionStorage.getItem(key)
		const parsedItems = JSON.parse(items != null ? items : '')
		if (width > 1023) {
			if (parsedItems.length >= 3) {
				e.preventDefault()
				if (
					window.confirm(`Items to compare cannot be more than 3.
Clicking OK will redirect you to the product comparison page.`)
				) {
					window.location.href = `/product-comparison#${key}`
				}
			} else {
				addToTable(brandPage, tagCheck, title)
			}
		} else {
			if (parsedItems.length >= 2) {
				console.log(parsedItems.length)
				e.preventDefault()
				if (
					window.confirm(`Items to compare cannot be more than 2.
Clicking OK will redirect you to the product comparison page.`)
				) {
					window.location.href = `/product-comparison#${key}`
				}
			} else {
				addToTable(brandPage, tagCheck, title)
			}
		}
	}

	return (
		<ItemSummaryContainer>
			<ItemDetailsContainer>
				{body ? documentToReactComponents(JSON.parse(body.raw), options) : 'No description yet for this product'}
				{categories === null ? (
					<SummaryCtaContainer>
						<Book
							tw="ml-0"
							className="alt"
							to={
								active === null || active === true
									? brand != 'Dolphin'
										? `https://mydolphin.co.za/contact-us`
										: series === 'Wave'
										? `https://mydolphin.co.za/contact-us`
										: `https://mydolphin.co.za/contact-us`
									: `https://mydolphin.co.za/contact-us`
							}
						>
							{active === null || active === true
								? brand != 'Dolphin'
									? 'Get a Quote'
									: series === 'Wave'
									? 'Request a Trial'
									: 'Enquire Now'
								: 'Enquire Now'}
						</Book>
						<SummaryButton
							href={
								comparison ? `/product-comparison#${brandPage.slug === 'dolphin' ? tagCheck : brandPage.slug}` : '#'
							}
							className="alt"
							onClick={(e) => {
								comparison
									? checkLength(
											brandPage.slug === 'dolphin' ? tagCheck : brandPage.slug,
											e,
											brandPage.slug,
											tagCheck,
											seoTitle
									  )
									: ''
							}}
						>
							Compare
						</SummaryButton>
					</SummaryCtaContainer>
				) : categories.slug != 'spare-parts' ? (
					<SummaryCtaContainer>
						<Book
							tw="ml-0"
							className="alt"
							to={
								active === null || active === true
									? brand != 'Dolphin'
										? `https://mydolphin.co.za/contact-us`
										: series === 'Wave'
										? `https://mydolphin.co.za/contact-us`
										: `https://mydolphin.co.za/contact-us`
									: `https://mydolphin.co.za/contact-us`
							}
						>
							{active === null || active === true
								? brand != 'Dolphin'
									? 'Get a Quote'
									: series === 'Wave'
									? 'Request a Trial'
									: 'Enquire Now'
								: 'Enquire Now'}
						</Book>
						<SummaryButton
							href={
								comparison ? `/product-comparison#${brandPage.slug === 'dolphin' ? tagCheck : brandPage.slug}` : '#'
							}
							className="alt"
							onClick={(e) => {
								comparison
									? checkLength(
											brandPage.slug === 'dolphin' ? tagCheck : brandPage.slug,
											e,
											brandPage.slug,
											tagCheck,
											seoTitle
									  )
									: ''
							}}
						>
							Compare
						</SummaryButton>
					</SummaryCtaContainer>
				) : (
					''
				)}
			</ItemDetailsContainer>
			<VideoContainer>
				{currentVid != null ? (
					<iframe
						title="Product Video"
						width="100%"
						height="100%"
						src={`https://www.youtube.com/embed/${currentVid}`}
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					></iframe>
				) : (
					<Novideo>No video at this time</Novideo>
				)}
				{filteredThumbs.length > 0 ? (
					<ThumbnailsContainer>
						{filteredThumbs.map((thumb, i: number) => {
							return (
								<ThumbContainer key={i}>
									<Thumbs src={`https://img.youtube.com/vi/${thumb}/mqdefault.jpg`} key={i}></Thumbs>
									<SvgContainer
										onClick={() => {
											setCurrentVid(thumb)
										}}
									>
										<StyledSvg width="60px" height="60px" color="#fff" />
									</SvgContainer>
								</ThumbContainer>
							)
						})}
					</ThumbnailsContainer>
				) : (
					''
				)}
			</VideoContainer>
		</ItemSummaryContainer>
	)
}
export default ItemSummary
